import React, { useState } from "react"
import { Layout, CenteredLayout } from "@components"
import { SCHOLAR_PROG_KEY, FUTURE_READY_KEY } from "../common/constants"
import { navigate } from "gatsby"

const Verification = () => {
  const [textInputValue, setTextInputValue] = useState("")
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const checkKeyPress = (e) => {
    if (e.key === "Enter") {
      handleButton()
    }
  }

  const handleButton = () => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true);
      switch (textInputValue.toUpperCase()) {
        case SCHOLAR_PROG_KEY:
          navigate("/community-scholar-program")
          break
        case FUTURE_READY_KEY:
          navigate("/future-ready")
          break
        default:
          alert("Scholar program code is incorrect. Please try again.")
          setIsButtonDisabled(false)
          break
      }
    }
    // if (textInputValue.toUpperCase() == SCHOLAR_PROG_KEY.toUpperCase())
    //   navigate("/community-scholar-program")
    // else alert("Scholar program code is incorrect. Please try again.")
  }

  return (
    <Layout>
      <CenteredLayout>
        <div className="w-1/4 m-auto">
          <div className="pb-2">
            <input
              type="text"
              className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none box-border"
              id="verificationFormInput"
              placeholder="Scholar Program Code"
              value={textInputValue}
              onKeyUp={checkKeyPress}
              onChange={e => setTextInputValue(e.target.value)}
            />
          </div>
          <div>
            <button
              type="button"
              onClick={handleButton}
              disabled={isButtonDisabled}
              className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
            >
              Access Program
            </button>
          </div>
        </div>
      </CenteredLayout>
    </Layout>
  )
}

export default Verification
